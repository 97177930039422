import React from 'react'
import { Link } from 'react-router-dom';
import b4 from '../../assets/gif2.webp'

const BlogCard5 = () => {
  return (
    <div>
                <div class="flex flex-col border border-gray-300 rounded-lg overflow-hidden shadow-lg bg-white">
            <Link to="/blog5"> 
            <img class="w-full h-48 object-cover" src={b4} alt="Descriptive Alt Text"/>
            <div class="p-4 flex flex-col flex-grow">
                <h2 class="font-bold text-xl mb-2 text-center">How AI is Making Math More Accessible for All Students</h2>
                <p class="text-gray-700 text-base flex-grow">
                    Explore the top ten advantages of integrating AI-powered tools in the classroom, from personalized learning to enhanced engagement and more efficient teaching.
                </p>
                <div class="flex items-center justify-between mt-4 text-gray-600 text-sm">
                    <span>By Paul L</span>
                    <span>5 min read</span>
                    <span>May 18, 2024</span>
                </div>
            </div>
            </Link>
        </div>
    </div>
  )
}

export default BlogCard5