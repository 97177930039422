import React from 'react'
import Navbar from './components/Navbar';
import Hero from './components/Hero.jsx';
import { Analytics }  from './components/Analytics.jsx';
import Emailer from './components/Emailer.jsx';
import Card from './components/Card.jsx';
import Footer from './components/Footer.jsx';
import Tag from './components/Tag.jsx';
import Process from './components/Process.jsx';


function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Tag />
      <Process />
      <Analytics/>
      <Emailer/>
      <Card/>
      <Footer/>
    </div>
  );
}

export default App;
