import React from 'react'
import Ai from '../assets/ai-100.png'
import Library from '../assets/library-100.png'
import Custom from '../assets/custom-100.png'
const Card = () => {
  return (
    <div className='w-full bg-white py-[10rem] px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
        
        <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Ai} alt="AI based worksheets generation"/>
        <h2 className='text-2xl font-bold text-center py-8'>Generative AI</h2>
        <p className='text-center font-small'> Utilization of cutting-edge AI technology to generate dynamic and engaging math problems</p>
        </div>

        <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Library} alt="lesson library selection when generating the worksheets"/>
        <h2 className='text-2xl font-bold text-center py-8'>Lesson Library</h2>
        <p className='text-center font-small'> The ability for teachers to select specific math lessons from KS1 & KS2 study programmes that will ensure that all generated questions are aligned with the UK curriculum standards.</p>
        </div>

        <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Custom} alt="adding custom branding to worksheets"/>
        <h2 className='text-2xl font-bold text-center py-8'>Custom Branding</h2>
        <p className='text-center font-small'> Personalize worksheets with your school or organization's branding for a professional touch.</p>
        </div>
      
      </div>
    </div>
  )
}

export default Card