import React from 'react'

import Layout from './Layout';


const Pricing = () => {
  return (
    <Layout>
     <div className="bg-[#71d694] min-h-screen flex items-center justify-center px-4 pb-8">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Get Started for Free!
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Join the Beta and Unlock All Features at No Cost
        </p>
        <p className="text-gray-700 mb-6">
          Welcome to <strong>Worksheep</strong>, your ultimate AI-powered worksheet creation tool! 
          As a special thank you for being an early adopter, we're offering 
          <span className="text-green-500 font-semibold"> 100% free access </span> 
          to all features during our beta phase. No credit card required, no hidden fees—just 
          the full power of our platform at your fingertips.
        </p>
        <div className="bg-green-100 p-4 rounded-lg mb-8">
          <h2 className="text-xl font-semibold text-green-700 mb-4">
            Why Join the Beta?
          </h2>
          <ul className="text-left text-gray-700 space-y-2">
            <li><strong>Unlimited Access:</strong> Create as many custom worksheets as you need, completely free.</li>
            <li><strong>Exclusive Features:</strong> Be the first to try new features as we roll them out.</li>
            <li><strong>Direct Feedback:</strong> Share your insights and help shape the future of Worksheep.</li>
            <li><strong>Early Adopter Perks:</strong> Enjoy special offers and discounts when we officially launch.</li>
          </ul>
        </div>
        <p className="text-gray-700 mb-8">
          <strong>How Long is the Beta?</strong><br />
          Our beta phase is limited, so don’t miss out! Sign up today and take advantage of this opportunity to simplify your lesson planning with Worksheep.
        </p>
        <p className="text-gray-700 mb-8">
          <strong>No Commitment. No Risk. Just Results.</strong><br />
          Your feedback is invaluable. We’re here to make Worksheep the best it can be, and we want you to be part of that journey.
        </p>
        <a 
          href="https://app.theworksheep.com/" target="_blank" rel="noopener noreferrer"
          className="inline-block bg-green-500 text-white text-lg font-semibold py-3 px-7 rounded-lg shadow hover:bg-green-600 transition duration-300"
        >
          Start Now!
        </a>
      </div>
    </div>
  </Layout>
);
};
export default Pricing