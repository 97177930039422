import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import { Link } from 'react-router-dom'

import logo from '../assets/sheep_logo.png'

const handleButtonClick = () => {
  window.open('https://app.theworksheep.com', '_blank');
};


const Navbar = () => {
  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }
  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-black'>
    
    <div className='flex flex-row items-end'>
      <h1 className='w-full text-3xl font-bold'>
        <Link to='/'>Worksheep</Link>
      </h1>
      <img className='w-[35px] h-[35px] ml-1' src={logo} alt="UK flag"/>
    </div>

    <ul className='flex-row sm:flex-row items-center justify-between space-x-4 hidden md:flex'>
            <Link to='/about' className='p-4 hover:scale-105'>About</Link>

            <li className='p-4 hover:scale-105'>
              <Link to='/blogs'>Blogs</Link>
            </li>
            <button className='bg-black w-[130px] rounded-md font-medium ml-2 my-6  py-3 text-white' onClick={handleButtonClick} > Start Now!</button>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
        </div>

        <div className={nav ? 'fixed left-0 top-0 w-[50%] h-full border-r border-r-gray  bg-black ease-in-out duration-500' : 'fixed left-[-100%]'}>
        
        <div className='flex flex-row items-end'>
          <h1 className='w-full text-3xl font-bold m-4 text-white'>Worksheep</h1>
          <img className='w-[35px] h-[35px] mr-16 my-4' src={logo} alt="UK flag"/>
        </div>


          <ul className='p-4 uppercase'>

    <li className='p-4 border-b border-gray-600'>
      <Link to='/about' className='text-white'>About</Link>
    </li>
    <li className='p-4 border-b border-gray-600'>
      <Link to='/' className='text-white'>Home</Link>
    </li>
    <li className='p-4 border-b border-gray-600'>
      <Link to='/blogs' className='text-white'>Blogs</Link>
    </li>
    <li className='p-4 border-b border-gray-600 hover:cursor-pointer'>
      <Link to='/' className='text-white' onClick={handleButtonClick}>Start Now!</Link>
    </li>
  </ul>
        </div>
    </div>
  )
}

export default Navbar