import React from 'react'
import { FaFacebookSquare, FaInstagramSquare, FaYoutube, FaTiktok } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
  
    <div className='bg-black mx-auto py-16 px-6 grid lg:grid-cols-3 gap-8 text-white'>
      <div>
      <h1 className='w-full text-3xl font-bold '>Worksheep 🐑</h1>
      <p className='py-4'> Worksheep is an AI-powered math worksheet generator. Say goodbye to manual worksheet creation and hello to instant, personalized worksheets now. Become a part of Worksheep today!</p>
      <p className='py-4'>hello@theworksheep.com </p>
      <p>© 2024 WorkSheep. All rights reserved.</p>
      <div className='flex justify-evenly md:w-[75%] my-6'>
      <a
        href="https://www.facebook.com/profile.php?id=61563133166252"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800"
      >
        <FaFacebookSquare size={30} />
      </a>
      <a
        href="https://www.instagram.com/theworksheeeeeep?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        target="_blank"
        rel="noopener noreferrer"
        className="text-pink-600 hover:text-pink-800"
      >
        <FaInstagramSquare size={30} />
      </a>
      <a
        href="https://www.youtube.com/@theworksheep"
        target="_blank"
        rel="noopener noreferrer"
        className="text-red-600 hover:text-red-800"
      >
        <FaYoutube size={30} />
      </a>
      <a
        href="https://www.tiktok.com/@theworksheeeeeep?is_from_webapp=1&sender_device=pc"
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-gray-800"
      >
        <FaTiktok size={30} />
      </a>
    </div>
        </div>

<div className='lg:col-span-2 flex justify-evenly mt-6'>

  <div>
    <h6 className='font-medium text-gray-400'>Links</h6>
    <ul>
      
      
      <li className='py-2 text-sm hover:underline'>
      <Link to='/support' target='_blank'>Support</Link>
      </li>
      
      
      <li className='py-2 text-sm hover:underline'>
      <Link to='/pricing' target='_blank'>Pricing</Link>
      </li>

      <li className='py-2 text-sm hover:underline'>
      <Link to='/affiliates' target='_blank'>Affiliates</Link>
      </li>

    </ul>
  </div>

  <div>
    <h6 className='font-medium text-gray-400'>Legal</h6>
    <ul>
    <a href="policies/Terms of Service - Worksheep.pdf" target="_blank" rel="noopener noreferrer">
      <li className='py-2 text-sm hover:underline'>Terms of Service</li>
    </a>
    <a href="policies/Privacy Policy - Worksheep.pdf" target="_blank" rel="noopener noreferrer">
      <li className='py-2 text-sm hover:underline'>Privacy Policy</li>
      </a>
      <a href="policies/GDPR Compliance Policy - Worksheep.pdf" target="_blank" rel="noopener noreferrer">
      <li className='py-2 text-sm hover:underline'>GDPR Compliance</li>
      </a>
    </ul>
  </div>

  <div>
    <h6 className='font-medium text-gray-400'>Company</h6>
    <ul>

      <li className='py-2 text-sm hover:underline'>
        <Link to='/about' target='_blank'>About</Link>
      </li>

      <Link to='/blogs' className='py-2 text-sm hover:underline'>Blogs</Link>

      <li className='py-2 text-sm hover:underline'>
        <Link to='/careers' target='_blank'>Careers</Link>
      </li>

    </ul>
  </div>

</div>

</div>
  )
}

export default Footer