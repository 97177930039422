import React from 'react'
import gif1 from '../../assets/AI.gif'
import { Link } from 'react-router-dom';


const BlogCard2 = () => {
  return (
    <div>
        <div class="flex flex-col border border-gray-300 rounded-lg overflow-hidden shadow-lg bg-white">
        <Link to="/blog2"> 
            <img class="w-full h-48 object-cover" src={gif1} alt="Descriptive Alt Text"/>
            <div class="p-4 flex flex-col flex-grow">
                <h2 class="font-bold text-xl mb-2 text-center">Top 10 Benefits of Using AI-Powered Tools in the Classroom</h2>
                <p class="text-gray-700 text-base flex-grow">
                    Explore the top ten advantages of integrating AI-powered tools in the classroom, from personalized learning to enhanced engagement and more efficient teaching.
                </p>
                <div class="flex items-center justify-between mt-4 text-gray-600 text-sm">
                    <span>By Anjalie R</span>
                    <span>6 min read</span>
                    <span>May 25, 2024</span>
                </div>
       
            </div>
        </Link>
        </div>
    </div>
  )
}

export default BlogCard2