import React from 'react'
import b1 from '../../assets/research.jpg'
import { Link } from 'react-router-dom';

const BlogCard1 = () => {
  return (
    <div>

<div class="flex flex-col border border-gray-300 rounded-lg overflow-hidden shadow-lg bg-white">
        <Link to="/blog1">
            <img class="w-full h-48 object-cover" src={b1} alt="Descriptive Alt Text"/>
            <div class="p-4 flex flex-col flex-grow">
                <h2 class="font-bold text-xl mb-2 text-center">Research-Backed Methods for Improving Math Skills in Students</h2>
                <p class="text-gray-700 text-base flex-grow">
                    Discover effective, research-based strategies to enhance students' mathematical abilities, ensuring a strong foundation and a love for learning math.
                </p>
                <div class="flex items-center justify-between mt-4 text-gray-600 text-sm">
                    <span>By Haran R </span>
                    <span>6 min read</span>
                    <span>May 25, 2024</span>
                </div>
            </div>
       </Link>
        </div>
    </div>
  )
}

export default BlogCard1