import React from 'react'

const Tag = () => {
  return (
    <div className='w-full py-16 px-6 bg-white'>
     <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 '>Did you know that math teachers in the UK spend over 12 hours a week creating and searching for educational content?</h1>
     <h1 className='md:text-2xl sm:text-xl text-xl text-right '>(Source: K-12 Market Advisors, Marci Goldberg) </h1>
    </div>
  )
}

export default Tag