import React from 'react';
import { Link } from 'react-router-dom';
import fourofour from '../assets/computer.png';

const Errorpage = () => {
  return (
    <div className="bg-[#71d694] min-h-screen flex flex-col items-center justify-center px-4 py-8">
      <h1 className="w-full text-3xl font-bold py-5 text-center text-black">
        <Link to="/">Worksheep🐑</Link>
      </h1>
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-3xl text-center">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-black">
          Oops! Page Not Found
        </h1>
        <p className="md:text-2xl sm:text-xl text-xl py-2 text-gray-700">
          Looks like you've stumbled upon uncharted territory.
        </p>
        <div
        className='  flex flex-col items-center'>
        <img
          src={fourofour}
          alt="404 Error"
          className="w-40 self-center py-8"
        />
        </div>
        <h1 className="text-center">
          <Link
            to="/"
            className="bg-green-500 text-white hover:bg-green-600 rounded px-4 py-2 font-semibold transition duration-300"
          >
            Go to Home Page
          </Link>
        </h1>
      </div>
    </div>
  );
};

export default Errorpage;