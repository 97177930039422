// src/routes.js
import App from './App';
import About from './components/About';
import Blog1 from './components/Blog Articles/Blog1';
import Blog2 from './components/Blog Articles/Blog2';
import Blog3 from './components/Blog Articles/Blog3';
import Blog4 from './components/Blog Articles/Blog4';
import Blog5 from './components/Blog Articles/Blog5';
import Blogs from './components/Blogs';
import CommingSoon from './components/CommingSoon';
import Errorpage from './components/Errorpage';
import Pricing from './components/Pricing';

const routes = [
  {
    path: '/',
    element: <App />,
    errorElement: <Errorpage />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/blogs',
    element: <Blogs />,
  },
  {
    path: '/support',
    element: <CommingSoon />,
  },
  {
    path: '/pricing',
    element: <Pricing />,
  },
  {
    path: '/affiliates',
    element: <CommingSoon />,
  },
  {
    path: '/blog1',
    element: <Blog1 />,
  },
  {
    path: '/blog2',
    element: <Blog2 />,
  },
  {
    path: '/blog3',
    element: <Blog3 />,
  },
  {
    path: '/blog4',
    element: <Blog4 />,
  },
  {
    path: '/blog5',
    element: <Blog5 />,
  }
];

export default routes;