import React from 'react'
import { Link } from 'react-router-dom'

const CommingSoon = () => {
  return (
   <div className="bg-[#71d694] min-h-screen flex flex-col items-center justify-center px-4 py-8">
      <h1 className="w-full text-3xl font-bold py-5 text-center text-black">
        <Link to="/">Worksheep🐑</Link>
      </h1>
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-3xl text-center">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-16 text-black">
          ✨Exciting Things Are on the Way!✨
        </h1>
        <p className="md:text-2xl sm:text-xl text-xl py-2 text-gray-700">
          We're working hard to bring you something amazing. Stay tuned!
        </p>
        <p className="md:text-2xl sm:text-xl text-xl py-2 pb-6 text-gray-700">
          For any inquiries, please contact us at <strong>hello@theworksheep.com</strong>
        </p>
        <h1 className="text-center">
          <Link
            to="/"
            className="inline-block bg-green-500 text-white text-lg font-semibold py-3 px-6 rounded-lg shadow hover:bg-green-600 transition duration-300"
          >
            Go to Home Page
          </Link>
        </h1>
      </div>
    </div>
  )
}

export default CommingSoon