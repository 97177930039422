import React from 'react'

import Layout from './Layout';


const About = () => {
  return (
    <Layout>
    <div className="bg-[#71d694] min-h-screen flex items-center justify-center px-4 pb-8">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          About Us
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Generating Worksheets has never been this Easy!
        </p>
        <p className="text-gray-700 mb-6">
          At <strong>Worksheep</strong>, we recognize the essential relationship between educators and students. This bond is the cornerstone of a student's academic and personal growth, fostering trust, curiosity, and a genuine love for learning. Our mission is to support this relationship by reducing the workload on teachers, allowing them to dedicate more time to their students.
        </p>
        <h2 className="text-xl font-semibold text-green-700 mb-4">
          Empower Learning Math with AI
        </h2>
        <p className="text-gray-700 mb-6">
          Did you know that math teachers in the UK spend over 12 hours a week creating and searching for educational content? (Source: K-12 Market Advisors, Marci Goldberg) This repetitive and time-consuming task often detracts from valuable teaching time. Our team, comprising former educators and students, understands these challenges firsthand. We've developed Worksheep, an AI-powered platform that generates math worksheets, so teachers can focus on what they do best—teaching and inspiring students.
        </p>
        <h2 className="text-xl font-semibold text-green-700 mb-4">
          Easy, Fast & Flexible for All Math Teachers!
        </h2>
        <p className="text-gray-700 mb-6">
          Worksheep's platform is designed with extensive input from thousands of educators, ensuring it meets the real needs of teachers. We know educators don't have time to learn complex systems. That's why our platform is user-friendly, intuitive, and ready to use from day one. Worksheep has already made the lives of over 10,000 educators easier, and we're on a mission to reach millions more.
        </p>
        <h2 className="text-xl font-semibold text-green-700 mb-4">
          Personalize Your Teaching
        </h2>
        <p className="text-gray-700 mb-6">
          Every educator operates within a unique context, which is why Worksheep is flexible and adaptable. Our worksheets are aligned with the UK's mathematics curriculum and can be tailored to various educational goals, student grade levels, and individual learning plans (IEPs). Whether you need to meet specific educational standards, adjust to your teaching philosophy, or build upon previous knowledge, Worksheep has you covered.
        </p>
        <h2 className="text-xl font-semibold text-green-700 mb-4">
          Join the Worksheep Community
        </h2>
        <p className="text-gray-700 mb-8">
          Say goodbye to the hassle of manual worksheet creation and embrace the ease of instant, personalized worksheets with Worksheep. Empower your teaching, enhance your students' learning, and become a part of the Worksheep community today!
        </p>
      </div>
    </div>
  </Layout>
);
};
export default About