import React from 'react'
import ukflag from '../assets/student.png'
import { Button } from 'react-scroll'

function Hero() {
  return (
    <div>
        
        <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <div className='flex items-center justify-center'>
          <div className='mx-auto text-center flex flex-row justify-center'>
        <p className='font-bold'>AI-Powered Worksheets Tailored to your Students' Needs!📝</p>
        {/* <img className='w-[24px] h-[24px] ml-1' src={ukflag} alt="UK flag"/> */}
        </div>
    </div>
            <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 text-green-700'>Create Math Worksheets in Seconds.</h1>
            <div>
                <p>Easy, Fast & Flexible for all Math Teachers!</p>
                <Button className='bg-black w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white' to="howto" 
      spy={true} 
      smooth={true} 
      offset={50} 
      duration={500}>
        Learn More!
      </Button>
            </div>

        </div>

    </div>
  )
}

export default Hero