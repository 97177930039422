import React from 'react'
import file from '../assets/file.png'
import gears from '../assets/gears.png'
import input from '../assets/edit.png'

const Process = () => {
  return (
    <div className='py-8'>
    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-center text-white'>Create a worksheet in 3 easy steps</h1>

    <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 py-1'>
        
        <div className='w-full flex flex-col p-4 my-7 rounded-lg hover:scale-105 duration-300'>
          
        <h2 className='text-2xl font-bold text-center py-6'>1️⃣ Input Customization</h2>
        <p className='text-center font-small'> Tailor the worksheet by selecting the year group, difficulty level, topic, and number of questions.</p>
        <img className='w-20 mx-auto my-3' src={input}  alt="Input Customization of worksheets"/>
        </div>

        <div className='w-full flex flex-col p-4 my-7 rounded-lg hover:scale-105 duration-300'>
          
        <h2 className='text-2xl font-bold text-center py-6'>2️⃣ Auto-Question Generation</h2>
        <p className='text-center font-small'> Automatically generate a variety of math problems based on the specified criteria.</p>
        <img className='w-20 mx-auto my-3' src={gears}  alt="automatic question generation"/>
        </div>

        <div className='w-full flex flex-col p-4 my-7 rounded-lg hover:scale-105 duration-300'>
          
        <h2 className='text-2xl font-bold text-center py-6'>3️⃣ Auto-Worksheet Assembly</h2>
        <p className='text-center font-small'> Questions will be automatically assembled into a cohesive worksheet layout ready for use.</p>
        <img className='w-20 mx-auto my-3 ' src={file} alt="automatic assembly of the questions into single worksheet"/>
        </div>
      
      </div>
    </div>
  )
}

export default Process